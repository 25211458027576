import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ScheduledServiceReport } from '@boosterfuels/types/types-ts'
import {
  ServiceReportFindOptions,
  ScheduledServiceReportFindResponse,
} from '../models/service-report'
import { setHttpParams } from './helpers/set-http-params.helper'

@Injectable({ providedIn: 'root' })
export class ScheduledServiceReportApi {
  constructor(private http: HttpClient) {}

  public find(params: ServiceReportFindOptions) {
    return this.http.get<ScheduledServiceReportFindResponse>(
      `/ScheduledServiceReport`,
      {
        params: setHttpParams(params),
      }
    )
  }

  public create(data: Partial<ScheduledServiceReport>) {
    return this.http.post<{ scheduledServiceReport: ScheduledServiceReport }>(
      '/ScheduledServiceReport/create',
      data
    )
  }

  public update(data: Partial<ScheduledServiceReport>) {
    return this.http.put<{ scheduledServiceReport: ScheduledServiceReport }>(
      '/ScheduledServiceReport/update',
      data
    )
  }

  public delete(id: string) {
    return this.http.delete<any>(`/ScheduledServiceReport/${id}`)
  }
}
